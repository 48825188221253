// throttle
(function(window) {
    var $ = window.jQuery || window.me || (window.me = {}),
        throttle = function(fn, timeout, callback, delayed, trailing, debounce) {
            timeout || (timeout = 100);
            var timer = false,
                lastCall = false,
                hasCallback = (typeof callback === "function"),
                startTimer = function(wrapper, args) {
                    timer = setTimeout(function(){
                        timer = false;
                        if (delayed || trailing) {
                            fn.apply(wrapper, args);
                            if (trailing) { lastCall = +new Date(); }
                        }
                        if (hasCallback) { callback.apply(wrapper, args); }
                    }, timeout);
                },
                wrapper = function(){
                    if (timer && !debounce) { return; }
                    if (!timer && !delayed) {
                        if (!trailing || (+new Date()-lastCall) > timeout) {
                            fn.apply(this, arguments);
                            if (trailing) { lastCall = +new Date(); }
                        }
                    }
                    if (debounce || !trailing) { clearTimeout(timer); }
                    startTimer(this, arguments);
                }
            if ($.guid) { wrapper.guid = fn.guid = fn.guid || $.guid++; }
            return wrapper;
        };
    $.throttle = throttle;
    $.debounce = function(fn, timeout, callback, delayed, trailing) {
        return throttle(fn, timeout, callback, delayed, trailing, true);
    };
})(this);

// Easing
jQuery.extend(jQuery.easing, {
    easeOutCubic: function(x, t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    }
});

// Equal height
$.fn.equalHeights = function() {
    var max_height = 0;
    $(this).each(function() {
        max_height = Math.max($(this).outerHeight(), max_height);
    });
    $(this).each(function() {
        $(this).css('min-height', Math.round(max_height));
    });
};

// Lock scroll
var lockScroll = function() {
    $html = $('html');
    $body = $('body');
    var initWidth = $body.outerWidth();
    var initHeight = $body.outerHeight();

    var scrollPosition = [self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft, self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop];
    $html.data('scroll-position', scrollPosition);
    $html.addClass('lock-scroll');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    var marginR = $body.outerWidth() - initWidth;
    var marginB = $body.outerHeight() - initHeight;
    $body.css({ 'margin-right': marginR, 'margin-bottom': marginB });
};

var unlockScroll = function() {
    $html = $('html');
    $body = $('body');
    $html.removeClass('lock-scroll');
    var scrollPosition = $html.data('scroll-position');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    $body.removeAttr('style');
};

// Modal window
var bindModal = function(modal) {
    $('#' + modal).attr('aria-hidden', false);
};

var unbindModal = function(modal) {
    $('#' + modal).attr('aria-hidden', true);
};

var removeModal = function(modal) {
    $('#' + modal).remove();
};

var modalType = $('body').data('load-modal');
if (modalType) {
    bindModal(modalType);
}

$(document)
    .on('click', '.modal-close:not(.modal-remove)', function() {
        $(this)
            .parents('.modal')
            .attr('aria-hidden', true);
    })
    .on('click', '.modal-remove', function() {
        $(this)
            .parents('.modal')
            .remove();
    })
    .on('keydown', function(e) {
        if (e.which === 27) {
            $('.modal').attr('aria-hidden', true);
        }
    });

$(document).ready(function() {
    // ObjectFit Lazy load
    if ($('.js-object-fit').length) {
        var $objectImages = $('img.js-object-fit');
        objectFitImages($objectImages);
    }
    // Main menu
    $('.toggle-menu').on('click', function() {
        $('html').toggleClass('aside-dropdown-open menu-open');
        lockScroll();
    });

    $('.close-menu').on('click', function() {
        $('html').removeClass('aside-dropdown-open menu-open');
        unlockScroll();
    });

    $(document).on('click', '.menu-open .aside-dropdown__overlay', function() {
        $('html').removeClass('aside-dropdown-open menu-open');
        unlockScroll();
    });

    $('.toggle-login').on('click', function() {
        $('html').toggleClass('aside-dropdown-open login-open');
        lockScroll();
    });

    $('.close-login').on('click', function() {
        $('html').removeClass('aside-dropdown-open login-open');
        unlockScroll();
    });

    $(document).on('click', '.login-open .aside-dropdown__overlay', function() {
        $('html').removeClass('aside-dropdown-open login-open');
        unlockScroll();
    });

    $(document).on('keydown', function(e) {
        if (e.which === 27 && $('html').is('.aside-dropdown-open')) {
            $('html').removeClass('aside-dropdown-open menu-open login-open');
            unlockScroll();
        }
    });

    // Accordion
    if ($('.js-accordion').length > 0) {
        function initAccordion($element) {
            var $trigger = $element.find('.js-accordion-trigger').first();
            var $content = $element.find('.js-accordion-content').first();
            if ($trigger.hasClass('is-active')) {
                $content.css({ display: 'block' });
            }
            $trigger.on('click', function() {
                $trigger.toggleClass('is-active');
                $content.slideToggle(300);
            });
        }
        $.each($('.js-accordion'), function() {
            initAccordion($(this));
        });
    }

    // BTT
    if ($('.js-btt').length > 0) {
        function initBtt($element) {
            $element.on('click', function() {
                var offset = $element.data('offset');
                if (offset === undefined) offset = 0;
                var parentSelector = $element.data('parent');
                if (parentSelector === undefined) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: 0 + offset
                    }, 300);
                } else {
                    var $parent = $(parentSelector);
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $parent.offset().top + offset
                    }, 300);
                }
            });
            if ($element.hasClass('js-btt--reveal')) {
                function bttReveal() {
                    var $window = $(window);
                    if ($window.scrollTop() >= 320) {
                        $element.addClass('is-visible');
                    } else {
                        $element.removeClass('is-visible');
                    }
                }
                bttReveal();
                $(window).on('scroll', $.throttle(bttReveal, 50, null, true));
            }
        }
        $.each($('.js-btt'), function() {
            initBtt($(this));
        });
    }

    // Sticky tabs nav
    if ($('.tabs-nav-sticky').length > 0) {
        function initSticky($element) {
            var options = {
                topSpacing: 0,
                zIndex: 999
            };
            var data_getStickyWidthFrom = $element.data('get-sticky-width-from');
            if (data_getStickyWidthFrom != undefined) {
                options = $.extend({}, options, { getWidthFrom: data_getStickyWidthFrom });
            }
            $element.sticky(options);
        }
        $.each($('.tabs-nav-sticky'), function() {
            var $element = $(this);
            if ($(window).width() < 767) {
                $element.unstick();
            } else {
                initSticky($element);
            }
        });
        $(window).on('scroll', $.throttle(function() {
            var $window = $(this);
            $.each($('.tabs-nav-sticky'), function() {
                var $element = $(this);
                var $parent = $($element.data('sticky-parent'));
                if ($window.scrollTop() >= $parent.offset().top + $parent.height()) {
                    $element.addClass('not-sticky');
                } else {
                    $element.removeClass('not-sticky');
                }
            });
        }, 50, null, true));
        $(window).on('resize', $.throttle(function() {
            var $window = $(this);
            $.each($('.tabs-nav-sticky'), function() {
                var $element = $(this);
                if ($window.width() < 767) {
                    $element.unstick();
                } else {
                    initSticky($element);
                }
            });
        }, 50, null, true));
    }

    // Presentation
    if ($('.presentation-slider').length > 0) {
        var presentation_slider = $('.presentation-slider'),
            presentation_fade = presentation_slider.data('fade'),
            presentation_autoplay = presentation_slider.data('autoplay'),
            presentation_autoplay_speed = presentation_slider.data('autoplay-speed'),
            presentation_dots_container = $('.presentation-dots .wrapper');

        presentation_slider.slick({
            fade: presentation_fade,
            autoplay: presentation_autoplay,
            autoplaySpeed: presentation_autoplay_speed,
            arrows: true,
            dots: true,
            appendDots: presentation_dots_container,
            adaptiveHeight: true
        });
    }

    // Tabs
    if ($('.line-tabs').length > 0) {
        $('.line-tabs').each(function(i, e) {
            $(this).organicTabs();
        });
    }

    // Selected offers
    if ($('.selected-offers').length > 0) {
        $('.selected-offers__item--inner').equalHeights();

        $('.selected-offers').each(function(i, e) {
            $('.selected-offers__slider', e).slick({
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 530,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

    // Activities
    if ($('.activities').length > 0) {
        $('.activities').each(function(i, e) {
            $('.activities__slider', e).slick({
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 530,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

    // Running out
    if ($('.running-out__scrollbar').length > 0) {
        $('.running-out__item').equalHeights();

        $('.running-out__scrollbar').mCustomScrollbar({
            axis: 'x',
            advanced: {
                autoExpandHorizontalScroll: true
            }
        });
    }

    // Excursions
    if ($('.excursions').length > 0) {
        $('.excursions__inner').equalHeights();
    }

    // Gallery
    $(document.body).on('click', '.offer__preview--link', function() {
        $(this).lightGallery({
            dynamic: true,
            html: true,
            dynamicEl: $(this).data('gallery-images')
        });
    });

    $('.js-lightgallery__preview').lightGallery({
        selector: '.js-lightgallery__button'
    });

    // Scrollable tables
    $('table:not(.excursions__table):not(.seats-table)').each(function(i, e) {
        $(e).wrapAll('<div class="scrollable-table" />');
    });

    // Hotels slider
    if ($('.hotels__slider').length > 0) {
        // $('.hotels__slider .hotels__item--inner').equalHeights();

        $('.hotels__slider .hotels').each(function(i, e) {
            $(e).slick({
                arrows: true,
                dots: false,
                adaptiveHeight: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1160,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 970,
                        settings: {
                            dots: true,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 540,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

    if ($('.offer-slider').length > 0) {
        $('.offer-slider').each(function(i, e) {
            $(e).slick({
                arrows: true,
                dots: true,
                adaptiveHeight: true
            });
        });
    }

    if ($('.bus-line').length > 0) {
        $('.bus-line').each(function(i, e) {
            $(e).slick({
                arrows: true,
                dots: false,
                variableWidth: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 766,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 2,
                            // arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 639,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 1,
                            dots: true
                        }
                    }
                ]
            });
        });
    }

    // Input travelers
    var placeDropdown = function() {
        $('.input-travelers__dropdown').each(function(i, e) {
            $(e).removeClass('left right top');

            var pos_left = $(e).offset().left + $(e).innerWidth() / 2 - $(e).outerWidth() / 2,
                pos_top = $(e).offset().top - $(e).outerHeight() - 14;

            if (pos_left < 0) {
                pos_left = $(e).offset().left + $(e).outerWidth() / 2 - 20;
                $(e).addClass('left');
            } else {
                $(e).removeClass('left');
            }

            if (pos_left + $(e).outerWidth() > $(window).width()) {
                pos_left = $(e).offset().left - $(e).outerWidth() + $(e).outerWidth() / 2 + 20;
                $(e).addClass('right');
            } else {
                $(e).removeClass('right');
            }

            if (pos_top > $(document).scrollTop()) {
                var pos_top = $(e).offset().top + $(e).outerHeight() + 16;
                $(e).addClass('top');
            } else {
                $(e).removeClass('top');
            }
        });
    };

    // Travelers default
    var travelers_total_value = 0;
    $('.input-travelers__query', $('.input-travelers')).each(function() {
        travelers_total_value += parseInt(this.value);
    });

    if (travelers_total_value > 0) {
        $('.input-travelers__input div', $('.input-travelers')).html(travelers_total_value + ' ' + texts.travelers);
    }

    if ($('.input-travelers').length > 0) {
        //		$('.input-travelers__query').val(0);
        //		$('.input-travelers__btn.dec').prop('disabled', true);

        placeDropdown();

        var resizePlaceDropdownTimer;

        $(window).on('resize scroll', function(e) {
            if ($('.input-travelers').is('.open')) {
                clearTimeout(resizePlaceDropdownTimer);

                resizePlaceDropdownTimer = setTimeout(function() {
                    placeDropdown();
                }, 150);
            }
        });
    }

    $('.input-travelers__input').on('click', function() {
        placeDropdown();
        $(this)
            .parents('.input-travelers')
            .toggleClass('open');
    });

    $(document).on('click', function(e) {
        if ($(e.target).closest($('.input-travelers')).length == 0) {
            $('.input-travelers').removeClass('open');
        }
    });

    $('.input-travelers__btn:not(.input-travelers__btn--children)').on('click', function(e) {
        var master = $(this).parents('.input-travelers'),
            input_row = $(this).parents('.input-travelers__row'),
            input = $('.input-travelers__query', input_row),
            input_min = $(input).attr('data-min'),
            input_max = $(input).attr('data-max'),
            input_value = $(input).val(),
            total_value = 0;

        if (
            $(this)
                .attr('class')
                .indexOf('inc') >= 0
        ) {
            input_value++;
        } else {
            input_value--;
        }

        if (input_value <= input_min) {
            $('.dec', input_row).prop('disabled', true);
        } else {
            $('.dec', input_row).prop('disabled', false);
        }

        if (input_value >= input_max) {
            $('.inc', input_row).prop('disabled', true);
        } else {
            $('.inc', input_row).prop('disabled', false);
        }

        $(input).val(input_value);

        $('.input-travelers__query', master).each(function() {
            total_value += parseInt(this.value);
        });

        if (total_value > 1) {
            $('.input-travelers__input div', master).html(total_value + ' ' + texts.travelers);
        } else if (total_value == 0) {
            $('.input-travelers__input div', master).html(texts.choose);
        } else {
            $('.input-travelers__input div', master).html(total_value + ' ' + texts.traveler);
        }
    });

    $('.input-travelers__btn--children').on('click', function(e) {
        var master = $(this).parents('.input-travelers'),
            input_row = $(this).parents('.input-travelers__row'),
            input = $('.input-travelers__query', input_row),
            input_min = $(input).attr('data-min'),
            input_max = $(input).attr('data-max'),
            input_value = $(input).val(),
            total_value = 0;

        if (
            $(this)
                .attr('class')
                .indexOf('inc') >= 0
        ) {
            input_value++;
        } else {
            input_value--;
        }

        if (input_value <= input_min) {
            $('.dec', input_row).prop('disabled', true);
        } else {
            $('.dec', input_row).prop('disabled', false);
        }

        if (input_value >= input_max) {
            $('.inc', input_row).prop('disabled', true);
        } else {
            $('.inc', input_row).prop('disabled', false);
        }

        $(input).val(input_value);

        $('.input-travelers__query', master).each(function() {
            total_value += parseInt(this.value);
        });

        if (total_value > 1) {
            $('.input-travelers__input div', master).html(total_value + ' ' + texts.travelers);
        } else if (total_value == 0) {
            $('.input-travelers__input div', master).html(texts.choose);
        } else {
            $('.input-travelers__input div', master).html(total_value + ' ' + texts.traveler);
        }

        $('.input-travelers__select--select', master)
            .removeClass('el-show')
            .find('select')
            .removeAttr('name');

        for (var i = 0; i < input_value; i++) {
            $('.input-travelers__select--select:eq(' + i + ')', master).addClass('el-show');
            $('.input-travelers__select--select:eq(' + i + ')  select', master).attr('name', $('.input-travelers__select--select:eq(' + i + ')  select', master).data('name'));
        }
    });

    // Reveal datepicker
    $('.reveal-datepicker__button').on('click', function() {
        $(this)
            .parents('.reveal-datepicker')
            .addClass('reveal-datepicker__active');

        $('.arrival-datepicker').attr('name', $('.arrival-datepicker').data('name'));
    });

    $('.reveal-datepicker__button--remove').on('click', function() {
        $(this)
            .parents('.reveal-datepicker')
            .removeClass('reveal-datepicker__active');
        $('.arrival-datepicker').removeAttr('name');
    });

    // Scroll down button
    $('.scroll-down__button').on('click', function() {
        $('body, html')
            .stop(true, true)
            .animate({ scrollTop: $('#' + $(this).data('scroll')).offset().top - 60 }, 1400, 'easeOutCubic');
    });

    // Prevent page zoom
    $(document).on('keydown', function(e) {
        if (e.ctrlKey == true && (e.which == '61' || e.which == '107' || e.which == '173' || e.which == '109' || e.which == '187' || e.which == '189')) {
            e.preventDefault();
        }
    });

    $(window).on('mousewheel DOMMouseScroll', function(e) {
        if (e.ctrlKey == true) {
            e.preventDefault();
        }
    });

    $(document).on('keydown', function(e) {
        if (e.ctrlKey && (e.key == 'p' || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
            e.cancelBubble = true;
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    });


    // Expandable dates
    $('.expandable-dates').each(function(i, e){

        var date_items = $('.expandable-dates__item:not(.expandable-dates__item--button)', e).length;
        var date_button = $('.js-expand__dates', e);

        if( date_items > 3 ) {

            date_button.html( date_button.data('more') +' '+ date_button.data('dates') );
            date_button.attr('data-more', date_button.data('more') +' '+  date_button.data('dates') );

            $(e).addClass('expandable');

            date_button.on('click', function(){

                $(e).toggleClass('expanded');

                $(this).text(function(i, text){
                    return text === $(this).attr('data-less') ? $(this).attr('data-more') : $(this).attr('data-less');
                });
            });
        }

    });
});
