// Validation errors messages for Parsley
// Load this after Parsley

Parsley.addMessages('ja', {
  dateiso:  "有効な日付を入力してください。 (YYYY-MM-DD).",
  minwords: "語句が短すぎます。 %s 語以上で入力してください。",
  maxwords: "語句が長すぎます。 %s 語以内で入力してください。",
  words:    "語句の長さが正しくありません。 %s 語から %s 語の間で入力してください。",
  gt:       "より大きい値を入力してください。",
  gte:      "より大きいか、同じ値を入力してください。",
  lt:       "より小さい値を入力してください。",
  lte:      "より小さいか、同じ値を入力してください。",
  notequalto: "異なる値を入力してください。"
});
