(function($){

	$.organicTabs = function(el, options){
		var base = this;
		base.$el = $(el);

		base.$nav = base.$el.find('.tabs-nav');
		base.$button = base.$el.find('.tabs-button');

		base.init = function(){
			
            base.options = $.extend({},$.organicTabs.defaultOptions, options);

			base.$nav.delegate('li', 'click', function(e){
				
				e.preventDefault();

				var curList = base.$el.find('a.active').attr('href').substring(1),
					$newList = $('a', this),
					listID = $newList.attr('href').substring(1);

				if( (listID != curList) && ( base.$el.find(':animated').length == 0) ){
                    
                    base.$el.find("#"+curList).removeClass('visible');
					base.$el.find("#"+listID).addClass('visible');

					base.$el.find('.tabs-nav li').removeClass('current');
					base.$el.find('.tabs-nav a').removeClass('active');
					$newList.parents('li').addClass('current');
					$newList.addClass('active');

					if( window.history && history.pushState ){
						history.replaceState('', '', '?' + base.options.param + '=' + listID);
					} 
					
					base.$button.text($newList.text());
                    
					$('.tabs-holder', base.$el).removeClass('tabs-open');

				}
				
			});
			
			var queryString = {};
			window.location.href.replace(
				new RegExp("([^?=&]+)(=([^&]*))?", "g"),
				function($0, $1, $2, $3) { queryString[$1] = $3; }
			);
			
			if( queryString[base.options.param] ){
			
				var tab = $("a[href='#" + queryString[base.options.param].split(/#(\S*)/g)[0] + "']");

				tab.closest('.tabs-nav').find('li').removeClass('current');
				tab.closest('.tabs-nav').find('a').removeClass('active');
				tab.closest('.line-tabs').find('.tab').removeClass('visible');
				tab.closest('.line-tabs').find('#'+ queryString[base.options.param].split(/#(\S*)/g)[0] +'').addClass('visible');
				tab.parents('li').addClass('current');
				tab.addClass('active');
			}; 
			
			base.$button.text(base.$el.find('.tabs-nav .active').text());
			
			base.$button.on('click', function(){
				$('.tabs-holder', base.$el).toggleClass('tabs-open');
			});
			
			
			$(document.body).on('click', function(e){				
				if( !$('.tabs-holder', base.$el).is('.tabs-open') ) return;
				
				if( $(e.target).closest( $('.tabs-holder', base.$el) ).length == 0 ){
					$('.tabs-holder', base.$el).removeClass('tabs-open');
				}				
			});
			
		};
		
		base.init();
	};

	$.organicTabs.defaultOptions = {
		'param': 'tab'
	};

	$.fn.organicTabs = function(options) {
		return this.each(function() {
		(new $.organicTabs(this, options));
	});
};
    
})(jQuery);