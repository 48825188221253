// Validation errors messages for Parsley
// Load this after Parsley

Parsley.addMessages('ua', {
  dateiso:  "Це значення повинно бути коректною датою (РРРР-ММ-ДД).",
  minwords: "Це значення повинно містити не менше %s слів.",
  maxwords: "Це значення повинно містити не більше %s слів.",
  words:    "Це значення повинно містити від %s до %s слів.",
  gt:       "Це значення повинно бути більше.",
  gte:      "Це значення повинно бути більше або дорівнює.",
  lt:       "Це значення повинно бути менше.",
  lte:      "Це значення повинно бути менше або дорівнює.",
  notequalto: "Це значення повинно відрізнятися."
});
