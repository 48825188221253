var language = document.documentElement.lang.substring(0, 2);

flatpickr.l10ns.default.firstDayOfWeek = 1;

function dropDown (dateObj, dateStr, instance) {

    var currentMonthNow = instance.currentMonth;
    var currentYearNow = instance.currentYear;
    var flatpickrYearElement = instance.currentYearElement;

    var children = flatpickrYearElement.parentElement.children;
    for (var i in children) {
        if (children.hasOwnProperty(i)) {
            children[i].style.display = 'none';
        }
    }

    var yearSelect = document.createElement('select');
    var minYear = new Date(instance.config._minDate).getFullYear();
    var maxYear = new Date(instance.config._maxDate).getFullYear();

    for (var i = minYear; i <= maxYear; i++) {
        var option = document.createElement('option');
        option.value = '' + i;
        option.text = '' + i;
        yearSelect.appendChild(option);
    }
    yearSelect.addEventListener('change', function (event) {
        flatpickrYearElement.value = parseInt(event.target.value);
        // instance.currentYear = parseInt(event.target.value);
        instance.changeYear(parseInt(event.target.value));

        if(instance.currentYear == currentYearNow){
            instance.changeMonth(currentMonthNow,false);
        } else {
            // if not current year show all months
            instance.changeMonth(currentMonthNow,false);
        }

        instance.redraw();
    });

    yearSelect.className = 'flatpickr-yearDropdown-years';
    yearSelect.id = 'custom-'+$(instance.element).attr('id');
    yearSelect.value = instance.currentYearElement.value;
    // yearSelect.value = parseInt(maxYear);
    flatpickrYearElement.parentElement.appendChild(yearSelect);
}
$(document).ready(function(){
    // Validate forms
    Parsley.setLocale(language);
    $('form:not([data-skip-validator="true"])').parsley();


    window.checkinDateCalendar = $('[name="gcheck_in_date"]').flatpickr({
        mode: 'range',
        altInput: true,
        altFormat: 'd M Y',
        minDate: 'today',
        "locale": language,
        animate: false,
        showMonths: 2,
        onReady: function(dateObj, dateStr, instance){
            var $datepicker = $(instance.calendarContainer);
            if ($datepicker.find('.flatpickr-clear').length < 1){
                $datepicker.append('<button type="button" class="flatpickr-clear">Изчисти</button>');
                $datepicker.find('.flatpickr-clear').on('click', function(){
                    instance.clear();
                    //instance.close();
                });
            }
        },
        // BACKEND NOTE - transport dates
        onDayCreate: function(dObj, dStr, fp, dayElem ){
            var calendarDate = new Date(dayElem.dateObj);
            var calendarDateFormated = calendarDate.getFullYear() + '-' + (calendarDate.getMonth() + 1) + '-' + calendarDate.getDate();

            $.each(transport.air, function(index, value) {
                var transportDateAir = new Date(value);
                var transportDateAirFormated = transportDateAir.getFullYear() + '-' + (transportDateAir.getMonth() + 1) + '-' + transportDateAir.getDate();
                if ( transportDateAirFormated == calendarDateFormated ) {
                    dayElem.className += '' + ' air';
                }
            });

            $.each(transport.bus, function(index, value) {
                var transportDateBus = new Date(value);
                var transportDateBusFormated = transportDateBus.getFullYear() + '-' + (transportDateBus.getMonth() + 1) + '-' + transportDateBus.getDate();
                if ( transportDateBusFormated == calendarDateFormated ) {
                    dayElem.className += '' + ' bus';
                }
            });
        },
        onChange: function(selectedDates, dateStr, instance) {
            instance.close();
        },
        onClose: function(selectedDates, dateStr, instance){
            var rangePeriod = parseFloat(instance.element.dataset.range) ? parseFloat(instance.element.dataset.range) : 9;
            if ( rangePeriod <= 0) {
                rangePeriod = 9;
            }
            if (selectedDates.length > 0) {
                var startDate = selectedDates[0];
                var endDate = startDate.fp_incr(rangePeriod - 1);
                instance.setDate([startDate, endDate]);
            }
        },
        onOpen: function(selectedDates, dateStr, instance) {
            if (selectedDates[0] !== undefined && selectedDates[1] !== undefined) {
                var selectedMonth1 = selectedDates[0].getMonth();
                var selectedMonth2 = selectedDates[1].getMonth();
                if ( selectedMonth1 !== selectedMonth2 ) {
                    instance.changeMonth(-1);
                }
            }
        }
    });

    // Demo forms
    $('[name="sort_by"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    /* Holiday form */
//    $.ajax({
//        type: 'GET',
//        dataType: 'json',
//        url: xhr_holiday_countries_url,
//        success: function (data){
//            $.each(data, function(index, element){
//                $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="gcountry"]');
//            });
//        }
//    });



   $('[name="gcountry"]').select2({
       width: '100%',
       language: language
   });

//    $('[name="gcheck_in_date"]').flatpickr({
//        mode: 'range',
//        altInput: true,
//        altFormat: 'd M Y',
//        minDate: 'today',
//        "locale": language,
//        animate: false
//    });
    /* EOF Holiday form */


    /* Excursion form */
//    $.ajax({
//        type: 'GET',
//        dataType: 'json',
//        url: xhr_excursion_countries_url,
//        success: function (data){
//            $.each(data, function(index, element){
//                $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="cid"]');
//            });
//        }
//    });

    $('[name="cid"]').select2({

        width: '100%',
        language: language

    })

    $('[name="dcheck_in_date"]').flatpickr({
		mode: 'range',
		altInput: true,
        altFormat: 'd M Y',
        minDate: 'today',
        "locale": language,
        animate: false
    });
    /* EOF Excursion form */


    /* Hotel form */
//    $.ajax({
//        type: 'GET',
//        dataType: 'json',
//        url: xhr_hotel_countries_url,
//        success: function (data){
//            $.each(data, function(index, element){
//                $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="hcountry"]');
//            });
//        }
//    });

    $('[name="hcountry"]').select2({
        width: '100%',
        language: language
    })

    $('[name="hcheck_in_date"]').flatpickr({
		mode: 'range',
		altInput: true,
        altFormat: 'd M Y',
        minDate: 'today',
        "locale": language,
        animate: false
    });
    /* EOF Hotel form */


    /* Bus form */
    // $.ajax({
    //     type: 'GET',
    //     dataType: 'json',
    //     url: xhr_bus_departure_cities_url,
    //     success: function (data){
    //         $.each(data, function(index, element){
    //             $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="depart_city"]');
    //         });
    //     }
    // });


    $('[name="depart_city"]').select2({
        width: '100%',
        language: language
    }).on('change', function(){

        if( this.value != -1){

            $.ajax({
                type: 'GET',
                dataType: 'json',
                url: 'temp/cities.json',
                success: function (data){

                    $('[name="arrive_city"]').html('').val('');

                    $.each(data, function(index, element){
                        $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="arrive_city"]');
                    });

                    $('<option value="-1">'+ $('[name="arrive_city"]').attr('data-placeholder') +'</option>').prependTo('[name="arrive_city"]');
                    $('[name="arrive_city"]').val('-1');
                }
            });

        } else {
            $('[name="arrive_city"]').html('').val('');
            $('<option value="-1">'+ $('[name="arrive_city"]').attr('data-placeholder') +'</option>').prependTo('[name="arrive_city"]');
            $('[name="arrive_city"]').val('-1');
        }

    });

    $('[name="arrive_city"]').select2({
        width: '100%',
        language: language
    })
    /* EOF Bus form */


    /* Filters */
    // $.ajax({
    //     type: 'GET',
    //     dataType: 'json',
    //     url: 'temp/countries.json',
    //     success: function (data){
    //         $.each(data, function(index, element){
    //             $('<option value="'+ element.id +'">'+ element.name +'</option>').appendTo('[name="scountry"]');
    //         });
    //     }
    // });

    $('[name="scountry"]').select2({
        width: '100%',
        language: language
    });

    $('[name="sdates"]').flatpickr({
		mode: 'range',
		altInput: true,
        altFormat: 'd M Y',
        minDate: 'today',
        "locale": language,
        animate: false
    });

    $('[name="sprice"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('[name="stransport"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('[name="sfilter"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });
    /* EOF Filters */


    /* Offer inner */
    if( $('[name="odate"]').length > 0 ){
        $('[name="odate"]').flatpickr({
            altInput: true,
            altFormat: 'd M Y',
            minDate: 'today',
            "locale": language,
            animate: false,
            enable: enabled_dates
        });
    }

    $('[name="otravelers"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });
    /* EOF Offer inner */


    /* Select hotel */
    $('[name="hcategory"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('[name="hprice"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('[name="hfilter"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    if( $('[name="hhdate"]').length > 0 ){

        var holidayDatepicker = $('[name="hhdate"]').flatpickr({
            altInput: true,
            altFormat: 'd M Y',
            minDate: 'today',
            "locale": language,
            enable: enabled_dates,
            animate: false,
            onChange: function(selectedDates, dateStr, instance){

                $('.pick-date').prop('checked', false);
                $('.pick-date[value="'+ dateStr +'"]').prop('checked', true);

            }
        });

        $(document).on('change', '.pick-date', function(){
            holidayDatepicker.setDate(new Date(this.value));
        });
    }

    if( $('[name="hhdate2"]').length > 0 ){

        var holidayDatepicker2 = $('[name="hhdate2"]').flatpickr({
            altInput: true,
            altFormat: 'd M Y',
            minDate: 'today',
            "locale": language,
            enable: enabled_dates,
            animate: false,
            onChange: function(selectedDates, dateStr, instance){

                $('.pick-prices-2').prop('checked', false);
                $('.pick-prices-2[value="'+ dateStr +'"]').prop('checked', true);

            }
        });

        $(document).on('change', '.pick-prices-2', function(){
            holidayDatepicker2.setDate(new Date(this.value));
        });
    }

    $('[name="hhbase"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('[name="hhroom"]').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });

    $('.custom-select2').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        language: language
    });
    /* EOF Select hotel */


    /* Bus tickets */
//    if( $('.arrival-datepicker').length > 0 ){
//        var arrivalDatepicker = $('.arrival-datepicker').flatpickr({
//            minDate: 'today',
//            "locale": language,
//            animate: false
//        });
//
//        $('.departure-datepicker').flatpickr({
//            minDate: 'today',
//            "locale": language,
//            animate: false,
//            onChange: function(selectedDates, dateStr, instance){
//
//                arrivalDatepicker.set('minDate', dateStr);
//                arrivalDatepicker.set('enable',  enabled_dates);
//
//            }
//        });
//    }
    /* EOF Bus tickets */


    /* Hotel page */
    if( $('[name="accomodation_date"]').length > 0 ){

        var accomodation_date = null,
            leaving_date = null,
            diff_date = null;

        var clearDiffDays = function(){
            $('.diff-days').removeClass('el-show');
        }

        var diffDays = function(leaving_date, accomodation_date){
            diff_date = Math.round((Date.parse(leaving_date) - Date.parse(accomodation_date)) / 86400000);

            if( diff_date === 1 ){
                $('.diff-days').html('<div>'+ diff_date + ' ' + texts.overnight +'</div>');
            } else {
                $('.diff-days').html('<div>'+ diff_date + ' ' + texts.overnights +'</div>');
            }

            $('.diff-days').addClass('el-show');
        }

        var accomodationDatepicker = $('[name="accomodation_date"]').flatpickr({
            altInput: true,
            altFormat: 'd M Y',
            minDate: 'today',
            "locale": language,
            enable: enabled_dates,
            animate: false,
            onChange: function(selectedDates, dateStr, instance){

                accomodation_date = selectedDates[0];
                leavingDatepicker.clear();

                clearDiffDays();

                if( accomodation_date != null && leaving_date != null ){
                    diffDays(leaving_date, accomodation_date);
                }

                $('.pick-accomodation-date').prop('checked', false);
                $('.pick-accomodation-date[value="'+ dateStr +'"]').prop('checked', true);

            }
        });

        $(document).on('change', '.pick-accomodation-date', function(){
            accomodationDatepicker.setDate(new Date(this.value));
            leavingDatepicker.clear();
            clearDiffDays();
        });


        var leavingDatepicker = $('[name="leaving_date"]').flatpickr({
            altInput: true,
            altFormat: 'd M Y',
            minDate: 'today',
            "locale": language,
            enable: enabled_dates,
            animate: false,
            onChange: function(selectedDates, dateStr, instance){

                leaving_date = selectedDates[0];

                if( accomodation_date != null && leaving_date != null ){
                    diffDays(leaving_date, accomodation_date);
                }

                $('.pick-leaving-date').prop('checked', false);
                $('.pick-leaving-date[value="'+ dateStr +'"]').prop('checked', true);

            }
        });

        $(document).on('change', '.pick-leaving-date', function(){
            leavingDatepicker.setDate(new Date(this.value));

            if( accomodationDatepicker.selectedDates[0] && leavingDatepicker.selectedDates[0] ){
                diffDays( leavingDatepicker.selectedDates[0], accomodationDatepicker.selectedDates[0] )
            }
        });

    }


    /* EOF Hotel page */
});