// Validation errors messages for Parsley
// Load this after Parsley

Parsley.addMessages('ru', {
  dateiso:  "Это значение должно быть корректной датой (ГГГГ-ММ-ДД).",
  minwords: "Это значение должно содержать не менее %s слов.",
  maxwords: "Это значение должно содержать не более %s слов.",
  words:    "Это значение должно содержать от %s до %s слов.",
  gt:       "Это значение должно быть больше.",
  gte:      "Это значение должно быть больше или равно.",
  lt:       "Это значение должно быть меньше.",
  lte:      "Это значение должно быть меньше или равно.",
  notequalto: "Это значение должно отличаться."
});
